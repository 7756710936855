<template>
  <section class="boardWrap">
    <navi-bar>
      {{$t('front.mypage.message')}}
    </navi-bar>

    <!--ul class="msgcount">
      <li>전체 받은 쪽지 <span class="rd">{{pageInfo.tatal_list_count}}</span>건</li>
      <li>쪽지보관은 최장 <span class="bbl">14</span>일 최대 <span class="bbl">20</span>개</li>
    </ul-->
    <p class="msgnum">{{$t('front.mypage.messageAll')}} {{pageInfo.tatal_list_count}}</p>
    <ul class="msgchkbtn">
      <li><input type="checkbox" @click="onAllCheck" /><a class="selec" @click="onRemoveMsg">{{$t('front.board.AllDelete')}}</a></li>
      <li><a class="ok" @click="onAllRead">{{$t('front.board.AllRead')}}</a></li>
    </ul>

    <div class="msgwrap">
      <template v-if="list && list.length > 0">
        <template v-for="item in list" v-bind:key="item.msgIdx">
          <ul :class="getStatusClass(item)">
            <li class="check"><input type="checkbox" v-model="item.checked"></li>

            <li class="msgcon">
              <a @click="onMessageClick(item, true)">
                <span class="msgicons"><em class="msgicon"></em><img src="@/assets/img/hawaiilogo.png" /></span>
                <span class="subject">
<!--                  <em class="noticeicon">안내</em>-->
<!--                  <em class="eventicon">이벤트</em>-->
                  {{item.msgTitle}}
                </span>
              </a>
            </li>
            <li class="msgdate">{{dateFormat(item.sendDate)}}</li>
            <!--li class="trashicon" @click="onRemoveOne(item)"></li-->
          </ul>
        </template>
      </template>
    </div>

    <!--div class="btnwrap">
      <a class="all" @click="onAllCheck">전체선택</a>
      <a class="selec" @click="onRemoveMsg">선택삭제</a>
      <a class="ok" @click="onAllRead">전체읽음처리</a>
    </div-->

    <board-filter-mobile  @onSearch="onSearch"/>

    <pagination-mobile :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="loadList" />

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'

import messageList from '@/views/member/mypage/message/list'
import BoardFilterMobile from '@/components/common/mobile/BoardFilterMobile'

export default {
  name: 'mobileMsg',
  mixins: [messageList],
  methods: {
    getStatusClass (item) {
      if (item.msgStatus === 'N') {
        return 'on'
      } else {
        return 'off'
      }
    }
  },
  components: {
    BoardFilterMobile,
    NaviBar,
    PaginationMobile
  },
  data () {
    return {

    }
  }
}
</script>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
